<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('MANAGEMENT.SETTING') }} API {{ $t('ASIDE.VENDORS') }}
    </p>

    <section class="sm-content">
      <div class="w-30 px15 m-b-20">
        <label>{{ $t('MANAGEMENT.TOURISM_PROVIDER') }}</label>
        <div class="icon-input">
          <input type="text" class="input" />
          <img src="/assets/img/icons/search-light.svg" alt="">
        </div>
      </div>

      <div class="d-flex flex-wrap m-t-30">
        <div class="w-30 px15 d-flex align-items-center">
          <label>
            {{ $t('MANAGEMENT.PACKAGE_REFRESH_TIME_DAYS_AHEAD', {num: 30}) }}
          </label>
        </div>
        <div class="w-60 px15 d-flex align-items-center flex-wrap">
          <div class="d-flex align-items-center">
            <input type="text" class="input text-center days-input" value="1" />
            <p class="px10 fs14">{{ $t('MANAGEMENT.DAYS') }}</p>
            <p class="px10 fs14">{{ $t('MANAGEMENT.AT') }}</p>
            <input type="text" class="input text-center at-input" value="02:30:00" />
          </div>
          <p class="px15 fs14">{{ $t('MANAGEMENT.EXCEPT_FOR_REFRESH_DAY', {NUM: 90}) }} *</p>
        </div>
        <div class="w-10 sync-now">
          <button class="primary-btn primary-btn_sm bg-2">
            {{ $t('MANAGEMENT.SYNC_NOW') }}
          </button>
        </div>

      </div>
      <div class="d-flex flex-wrap m-t-40">
        <div class="w-30 px15 d-flex align-items-center">
          <label>
            {{ $t('MANAGEMENT.REFRESH_TIME_FORWARD_EACH_DAY', {num: 90}) }}
          </label>
        </div>
        <div class="w-60 px15 d-flex align-items-center">
          <input type="text" class="input text-center days-input" value="שבת" />
          <p class="px10 fs14">{{ $t('MANAGEMENT.DAYS') }}</p>
          <p class="px10 fs14">{{ $t('MANAGEMENT.AT') }}</p>
          <input type="text" class="input text-center at-input" value="02:30:00" />
        </div>
        <div class="w-10 sync-now">
          <button class="primary-btn primary-btn_sm bg-2">
            {{ $t('MANAGEMENT.SYNC_NOW') }}
          </button>
        </div>
      </div>
      <div class="px15"> 
        <button class="primary-btn save_btn">
          {{ $t('COMMON.SAVE') }}
        </button>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "TApiSettings"
}
</script>

<style lang="scss" scoped>
  .sm-content {
    max-width: 1100px;
  }
  p {
    margin-bottom: 0;
  }
  .days-input {
    width: 90px;
  }
  .at-input {
    width: 130px;
  }
  .save_btn {
    margin: 0 auto;
    margin-top: 80px;
  }

  @media screen and (max-width: 900px) {
    .w-10, 
    .w-30, 
    .w-40,
    .w-60 {
      width: 100% !important;
    }

    label {
      margin-bottom: 15px;
    }
    .sync-now {
      margin-top: 10px;
      padding: 0 10px;
    }
  }
  @media screen and (max-width: 400px) {
    .sm-content {
      padding: 0;
    }
  }

</style>